import React, { Component } from 'react'
import Square from './Square.js'

const boardStart = { 
  board: [
    ['br', 'bh', 'bb', 'bq', 'bk', 'bb', 'bh', 'br'],
    ['bp', 'bp', 'bp', 'bp', 'bp', 'bp', 'bp', 'bp'],
    ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
    ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
    ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
    ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
    ['wp', 'wp', 'wp', 'wp', 'wp', 'wp', 'wp', 'wp'],
    ['wr', 'wh', 'wb', 'wq', 'wk', 'wb', 'wh', 'wr']
  ]
}


//import { ThemeConsumer } from 'styled-components'; //idk why I put this here
class Board extends Component {
  constructor (props) {
    super(props)
    this.state = {
      squares: Array(8)
        .fill(null)
        .map(row => new Array(8).fill(null)),
      lastMove: {
        squares: [],
        rooksMoved: [false, false, false, false],
        kingsMoved: [false, false]
      },
      selected: [null, null],
      selectedMoves: null,
      whitesTurn: true,
      rooksMoved: [false, false, false, false],
      kingsMoved: [false, false],
      check: '',
      pawnSelection: 'n',
      enpassantx: -1,
      enpassanty: -1,
      hovered: [null, null]
    }
  }
  async componentDidMount () {
    this.defaultBoard()
  }
  defaultBoard = () => {
    const squares = this.state.squares.slice()

    
    for (var i = 0; i < 8; i++) {
      for (var j = 0; j < 8; j++) {
        var str = boardStart.board[i][j]
        squares[j][i] = str
      }
    }
    var selected = [null, null]
    var selectedMoves = []
    var whitesTurn = true
    var rooksMoved = [false, false, false, false]
    var kingsMoved = [false, false]
    this.setState({
      squares,
      selected,
      selectedMoves,
      whitesTurn,
      rooksMoved,
      kingsMoved,
      check: '',
      pawnSelection: 'n'
    })
  }
  goBack = () => {
    const old = this.state.lastMove
    const replacement = Array(8)
      .fill(0)
      .map(row => new Array(8).fill(false))
    //const empty = Array(8).fill(0).map(row => new Array(8).fill(false));
    var i, j
    if (old.squares === null || old.squares.length === 0) {
      console.log('Can only go back one move.')
    } else {
      for (i = 0; i < 8; i++) {
        for (j = 0; j < 8; j++) {
          replacement[i][j] = old.squares[i][j]
        }
      }
      this.setState({
        squares: replacement,
        lastMove: {
          squares: [],
          rooksMoved: this.state.lastMove.rooksMoved,
          kingsMoved: this.state.lastMove.kingsMoved
        },
        selected: [null, null],
        selectedMoves: [],
        rooksMoved: this.state.lastMove.rooksMoved,
        kingsMoved: this.state.lastMove.kingsMoved,
        whitesTurn: this.state.whitesTurn ? false : true
      })
    }
  }
  handlePawn (x, y) {
    // console.log("handle pawn x, y = [" + x + ", " + y +"]")
    var color = 'b'
    var piece = y
    var change = false
    if (y >= 10) {
      color = 'w'
      piece -= 10
    }

    var pieceStr = color
    switch (piece) {
      case 0: //rook
        pieceStr += 'r'
        break
      case 1: //knight
        pieceStr += 'h'
        break
      case 2: //bishop
        pieceStr += 'b'
        break
      case 3: //queen
        pieceStr += 'q'
        break
      default: //queen
        pieceStr += 'q'
        break
    }
    // console.log("piecestr: " + pieceStr)
    const squares = this.state.squares.slice()
    for (var i = 0; i < 8; i++) {
      if (squares[i][x] === color + 'p') {
        squares[i][x] = pieceStr
        change = true
      }
    }
    if (change) {
      this.setState({ squares, pawnSelection: 'n' })
    }
  }
  /********************** HANDLE HOVER / MAKE MOVE **********************/
  handleHover (x, y) {
    const selected = this.state.selected.slice()
    if (selected[0] != null && selected[1] != null) {
      return
    }
    const squares = this.state.squares.slice()
    var hoveredPiece = squares[x][y]
    var whitesTurn = this.state.whitesTurn
    var turn = whitesTurn ? 'w' : 'b'
    if (hoveredPiece[0] !== turn) {
      this.setState({
        selectedMoves: []
      })
      return
    }
    var moves = this.state.selectedMoves
    const hovered = this.state.hovered.slice()
    hovered[0] = x
    hovered[1] = y
    moves = this.getMoves(hoveredPiece, x, y, whitesTurn)
    if (moves.length === 0) {
      moves = null
    }
    this.setState({
      squares,
      hovered,
      whitesTurn,
      selectedMoves: moves
    })
  }
  /********************** HANDLE CLICK / MAKE MOVE **********************/
  handleClick (x, y) {
    // console.log("clicked " + x + ", " + y);
    if (x === 9) {
      if (this.state.pawnSelection === 'w') this.handlePawn(0, y)
      if (this.state.pawnSelection === 'b') this.handlePawn(7, y)
    } else if (this.state.pawnSelection === 'n') {
      //change states
      const squares = this.state.squares.slice()
      var i,
        j = 0
      const selected = this.state.selected.slice()
      var x0 = selected[0]
      var y0 = selected[1]
      var moves = this.state.selectedMoves
      var whitesTurn = this.state.whitesTurn
      var turn = whitesTurn ? 'w' : 'b'
      var clicked = squares[x][y]
      var hovered = this.state.hovered.slice()

      const old = Array(8)
        .fill(0)
        .map(row => new Array(8).fill(false))
      for (i = 0; i < 8; i++) {
        for (j = 0; j < 8; j++) {
          old[i][j] = squares[i][j]
        }
      }
      var lastClicked = (x0 === null) | (y0 === null) ? null : squares[x0][y0]

      //no previous selection, selection has to be same as persons turn
      if ((lastClicked !== null) & (clicked[0] !== turn)) {
        //selected[0] = x; selected[1] = y;
        if (lastClicked[0] === turn) {
          // previous selected was turn
          var works = false
          for (i = 0; i < moves.length; i++) {
            if ((moves[i][0] === x) & (moves[i][1] === y)) {
              works = true
            }
          }
          if (works) {
            this.setState({
              lastMove: {
                squares: old,
                rooksMoved: this.state.rooksMoved,
                kingsMoved: this.state.kingsMoved
              }
            })
            this.checkCastling(x0, y0)

            squares[x][y] = lastClicked
            squares[x0][y0] = 'n'
            //If this was an en passant, Murder that pawn.
            if (x === this.state.enpassantx && y === this.state.enpassanty) {
              squares[x][y + (whitesTurn ? 1 : -1)] = 'n'
            }

            //If this was a castle, move the rook!
            if (lastClicked[1] === 'k') {
              if (x - x0 === 2) {
                squares[x0 + 1][y] = lastClicked[0] + 'r'
                squares[7][y] = 'n'
              } else if (x - x0 === -2) {
                squares[x0 - 1][y] = lastClicked[0] + 'r'
                squares[0][y] = 'n'
              }
            }
            //If this was pawn promotion (if pawn on final row), open pawn selection
            if (lastClicked === 'wp' && y === 0) {
              //turn piece selector display on
              this.setState({ pawnSelection: 'w' })
            }
            if (lastClicked === 'bp' && y === 7) {
              this.setState({ pawnSelection: 'b' })
            }

            if (
              (lastClicked === 'bp' && y === 3 && y0 === 1) ||
              (lastClicked === 'wp' && y === 4 && y0 === 6)
            ) {
              this.setState({ enpassantx: x })
              var newy = lastClicked[0] === 'w' ? 5 : 2
              this.setState({ enpassanty: newy })
            } else {
              this.setState({ enpassantx: -1 })
              this.setState({ enpassanty: -1 })
            }
            selected[0] = null
            selected[1] = null
            moves = []
            whitesTurn = whitesTurn ? false : true

            var player = whitesTurn ? 'w' : 'b'
            var playerStr = whitesTurn ? 'White' : 'Black'
            var threat = whitesTurn ? 'b' : 'w'
            var king = this.findKing(player)
            if (this.isThreatened(king[0], king[1], threat)) {
              playerStr += ' is in check!'
              this.setState({ check: playerStr })
            } else {
              this.setState({ check: '' })
            }
          } else {
            selected[0] = null
            selected[1] = null
            moves = null
          }
        } else {
          selected[0] = x
          selected[1] = y
          moves = this.getMoves(clicked, x, y, whitesTurn)
        }
      } else {
        if (clicked[0] === turn) {
          selected[0] = x
          selected[1] = y
          hovered[0] = x
          hovered[1] = y
          console.log('Clearing selected one')
          moves = this.getMoves(clicked, x, y, whitesTurn)
        } else {
          selected[0] = null
          selected[1] = null
          moves = null
        }
      }

      this.setState({
        squares,
        selected,
        whitesTurn,
        selectedMoves: moves
      })
    }
  }

  /********************** CHECK CASTLING PARAMATERS **********************/
  checkCastling (x0, y0) {
    const rooksMoved = this.state.rooksMoved.slice()
    const kingsMoved = this.state.kingsMoved.slice()

    if (y0 === 0) {
      if (x0 === 0) {
        rooksMoved[0] = true
      }
      if (x0 === 7) {
        rooksMoved[1] = true
      }
    }
    if (y0 === 7) {
      if (x0 === 0) {
        rooksMoved[2] = true
      }
      if (x0 === 7) {
        rooksMoved[3] = true
      }
    }
    if (x0 === 4 && y0 === 0) {
      kingsMoved[0] = true
    }
    if (x0 === 3 && y0 === 7) {
      kingsMoved[1] = true
    }

    this.setState({ rooksMoved, kingsMoved })
  }
  whatsHere (x, y) {
    const squares = this.state.squares.slice()
    if ((x >= 0) & (x < 8) & (y >= 0) & (y < 8)) {
      return squares[x][y]
    } else return 'o'
  }

  findKing (player) {
    for (var i = 0; i < 8; i++) {
      for (var j = 0; j < 8; j++) {
        if (this.state.squares[i][j] === player + 'k') {
          return [i, j]
        }
      }
    }
    return [0, 0]
  }
  findPieces (piece) {
    var pieces = []
    for (var i = 0; i < 8; i++) {
      for (var j = 0; j < 8; j++) {
        if (this.state.squares[i][j] === piece) {
          pieces.push([i, j])
        }
      }
    }
    return pieces
  }
  /********************** IS THREATENED ? **********************/
  // checks whether tile at x, y { 0-7 } is threatened by threat { "b", "w"}
  //squares,
  isThreatened (x, y, threat) {
    // console.log("isthreatened called with " + x + ", " + y + ", " + threat)
    var whitesTurn = threat === 'w' ? true : false
    var m = 0
    var dir, more, c, there
    const squares = this.state.squares.slice()
    var up = whitesTurn ? -1 : 1
    var i, j
    for (i = 0; i < 8; i++) {
      for (j = 0; j < 8; j++) {
        var piece = squares[i][j]
        if (piece[0] === threat) {
          switch (piece[1]) {
            case 'p':
              if (i + 1 === x && j + up === y) {
                return true
              }
              if (i - 1 === x && j + up === y) {
                return true
              }
              break
            case 'r':
              if (x === i) {
                dir = -1
                if (y > j) {
                  dir = 1
                }
                more = true
                c = 1
                while (more) {
                  // console.log("Vcheck rook [" + i + ", " + j + "] can attack " + i + "," + (j + dir * c));
                  there = this.whatsHere(i, j + dir * c)
                  if (there !== 'n') {
                    more = false
                  }
                  if (j + dir * c === y) {
                    return true
                  }
                  c++
                }
              }

              if (y === j) {
                dir = -1
                if (x > i) {
                  dir = 1
                }
                more = true
                c = 1
                while (more) {
                  //console.log("Hcheck rook [" + i + ", " + j + "] can attack " + (i+ dir * c) + "," + j);
                  there = this.whatsHere(i + dir * c, j)
                  if (there !== 'n') {
                    more = false
                  }
                  if (i + dir * c === x) {
                    return true
                  }
                  c++
                }
              }
              break
            case 'h':
              const knightMoves = [
                [1, 2],
                [2, 1],
                [-1, 2],
                [-2, 1],
                [1, -2],
                [2, -1],
                [-1, -2],
                [-2, -1]
              ]
              for (m = 0; m < knightMoves.length; m++) {
                if (
                  i + knightMoves[m][0] === x &&
                  j + knightMoves[m][1] === y
                ) {
                  return true
                }
              }
              break
            case 'b':
              const bishopMoves = [
                [-1, -1],
                [1, 1],
                [1, -1],
                [-1, 1]
              ]
              for (m = 0; m < bishopMoves.length; m++) {
                dir = bishopMoves[m]
                more = true
                c = 1
                while (more) {
                  // console.log("check bishop [" + i + ", " + j + "] can attack " + (i+dir[0]*c) + "," + (j+dir[1]*c));

                  there = this.whatsHere(i + dir[0] * c, j + dir[1] * c)
                  // console.log("bishop debug, there:" + there);
                  if (there !== 'n') {
                    more = false
                  }
                  if (i + dir[0] * c === x && j + dir[1] * c === y) {
                    return true
                  }
                  c++
                }

                if (
                  i + bishopMoves[m][0] === x &&
                  j + bishopMoves[m][1] === y
                ) {
                  return true
                }
              }
              break
            case 'k':
              const kingMoves = [
                [-1, 0],
                [0, 1],
                [1, 0],
                [0, -1],
                [-1, -1],
                [1, 1],
                [1, -1],
                [-1, 1]
              ]
              for (m = 0; m < kingMoves.length; m++) {
                if (i + kingMoves[m][0] === x && j + kingMoves[m][1] === y) {
                  return true
                }
              }

              break
            case 'q':
              const queenMoves = [
                [-1, 0],
                [0, 1],
                [1, 0],
                [0, -1],
                [-1, -1],
                [1, 1],
                [1, -1],
                [-1, 1]
              ]
              for (m = 0; m < queenMoves.length; m++) {
                dir = queenMoves[m]
                more = true
                c = 1
                while (more) {
                  // console.log("Queen["+x+", "+y+"]being checked in dir [" +dir+"], by "+ c +" tiles");
                  there = this.whatsHere(i + dir[0] * c, j + dir[1] * c)
                  if (there !== 'n') {
                    more = false
                  }
                  if (i + dir[0] * c === x && j + dir[1] * c === y) {
                    return true
                  }
                  c++
                }

                if (i + queenMoves[m][0] === x && j + queenMoves[m][1] === y) {
                  return true
                }
              }
              break
            default:
              return []
          }
        }
      }
    }
    return false // never returned true, therefore return false.
  }
  /*************** CHECK MOVE ***************/
  // Used by getMoves to check if move is legal before pushing
  // if a move will cause the king to be threatened it is illegal.
  checkMove (piece, x0, y0, x, y, opponent) {
    var player = opponent === 'w' ? 'b' : 'w'
    var legal = true
    const squares = this.state.squares // not using slice because slice isnt doing anything anyway
    var temp = squares[x][y]
    squares[x][y] = piece
    squares[x0][y0] = 'n'
    var king = this.findKing(player) // maybe move to input if it makes sense to , i.e. why call multiple times
    //squares,
    if (this.isThreatened(king[0], king[1], opponent)) {
      // console.log("illegal move found");
      legal = false
    }

    squares[x][y] = temp
    squares[x0][y0] = piece
    return legal
  }
  /********************** GET MOVES **********************/
  getMoves (piece, x, y, whitesTurn) {
    var pieceType = piece[1]
    var moves = []
    var whosOpponent = whitesTurn ? 'b' : 'w'
    var up = whitesTurn ? -1 : 1
    var pawnStart = 3.5 - up * 2.5
    var i, more, move, c, x1, y1, there

    switch (pieceType) {
      case 'p':
        if (this.whatsHere(x, y + up) === 'n') {
          if (this.checkMove(piece, x, y, x, y + up, whosOpponent)) {
            moves.push([x, y + up])
          }
          if (y === pawnStart && this.whatsHere(x, y + up * 2) === 'n') {
            if (this.checkMove(piece, x, y, x, y + 2 * up, whosOpponent)) {
              moves.push([x, y + 2 * up])
            }
          }
        }
        if (this.whatsHere(x + 1, y + up)[0] === whosOpponent) {
          if (this.checkMove(piece, x, y, x + 1, y + up, whosOpponent)) {
            moves.push([x + 1, y + up])
          }
        }
        if (this.whatsHere(x - 1, y + up)[0] === whosOpponent) {
          if (this.checkMove(piece, x, y, x - 1, y + up, whosOpponent)) {
            moves.push([x - 1, y + up])
          }
        }
        //check en passant
        //console.log("is " + x +" one away from " + this.state.enpassantx +"? and is " + (y+up) + " = " + this.state.enpassanty)
        //console.log((this.state.enpassantx === x + 1) +" OR " + (this.state.enpassantx === x - 1) + " AND " + (this.stateenpassanty === y + up))
        var enpX = this.state.enpassantx
        var enpY = this.state.enpassanty
        if (enpX > 0 && enpY > 0) {
          if (this.whatsHere(enpX, enpY - up)[0] === whosOpponent) {
            if (enpX === x + 1 && enpY === y + up) {
              if (this.checkMove(piece, x, y, x + 1, y + up, whosOpponent)) {
                moves.push([x + 1, y + up])
              }
            }
            if (enpX === x - 1 && enpY === y + up) {
              if (this.checkMove(piece, x, y, x - 1, y + up, whosOpponent)) {
                moves.push([x - 1, y + up])
              }
            }
          }
        }
        break
      case 'r':
        const rookMoves = [
          [-1, 0],
          [0, 1],
          [1, 0],
          [0, -1]
        ]
        for (i = 0; i < rookMoves.length; i++) {
          more = true
          move = rookMoves[i]
          c = 1
          while (more) {
            // go infinite in each direction till I hit enemy or wall
            x1 = c * move[0]
            y1 = c * move[1]
            there = this.whatsHere(x + x1, y + y1)
            if (there === 'n') {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
            } else if (there[0] === whosOpponent) {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
              more = false
            } else more = false
            c++
          }
        }
        break
      case 'h':
        const knightMoves = [
          [1, 2],
          [2, 1],
          [-1, 2],
          [-2, 1],
          [1, -2],
          [2, -1],
          [-1, -2],
          [-2, -1]
        ]
        for (i = 0; i < knightMoves.length; i++) {
          move = knightMoves[i]

          x1 = move[0]
          y1 = move[1]
          there = this.whatsHere(x + x1, y + y1)
          if (there === 'n' || there[0] === whosOpponent) {
            if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
              moves.push([x + x1, y + y1])
            }
          }
        }
        break
      case 'b':
        const bishopMoves = [
          [-1, -1],
          [1, 1],
          [1, -1],
          [-1, 1]
        ]
        for (i = 0; i < bishopMoves.length; i++) {
          more = true
          move = bishopMoves[i]
          c = 1
          while (more) {
            // go infinite in each direction till I hit enemy or wall
            x1 = c * move[0]
            y1 = c * move[1]
            there = this.whatsHere(x + x1, y + y1)
            if (there === 'n') {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
            } else if (there[0] === whosOpponent) {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
              more = false
            } else more = false
            c++
          }
        }
        break
      case 'k':
        const kingMoves = [
          [-1, 0],
          [0, 1],
          [1, 0],
          [0, -1],
          [-1, -1],
          [1, 1],
          [1, -1],
          [-1, 1]
        ]
        for (i = 0; i < kingMoves.length; i++) {
          move = kingMoves[i]

          x1 = move[0]
          y1 = move[1]
          there = this.whatsHere(x + x1, y + y1)
          if (there === 'n' || there[0] === whosOpponent) {
            if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
              moves.push([x + x1, y + y1])
            }
          }
        }
        var index = whosOpponent === 'w' ? 0 : 1
        var kingMoved = this.state.kingsMoved[index]
        var king = this.findKing(whosOpponent === 'w' ? 'b' : 'w')
        var rooks = this.state.rooksMoved
        var legal
        var rookOne = rooks[index * 2]
        var rookTwo = rooks[index * 2 + 1]
        /* DOESNT WORK FOR BLACK KING ! double check king castling rules*/
        if (!kingMoved) {
          if (!this.isThreatened(king[0], king[1], whosOpponent)) {
            if (!rookOne) {
              //left rook
              legal = true
              for (i = 1; i < king[0]; i++) {
                if (
                  this.whatsHere(i, king[1]) !== 'n' ||
                  this.isThreatened(i, king[1], whosOpponent)
                ) {
                  legal = false
                }
              }
              if (legal) {
                moves.push([king[0] - 2, king[1]])
              }
            }
            if (!rookTwo) {
              //right rook
              legal = true
              for (i = 6; i > king[0]; i--) {
                if (
                  this.whatsHere(i, king[1]) !== 'n' ||
                  this.isThreatened(i, king[1], whosOpponent)
                ) {
                  legal = false
                }
              }
              if (legal) {
                moves.push([king[0] + 2, king[1]])
              }
            }
          }
        }
        break
      case 'q':
        const queenMoves = [
          [-1, 0],
          [0, 1],
          [1, 0],
          [0, -1],
          [-1, -1],
          [1, 1],
          [1, -1],
          [-1, 1]
        ]
        for (i = 0; i < queenMoves.length; i++) {
          more = true
          move = queenMoves[i]
          c = 1
          while (more) {
            // go infinite in each direction till I hit enemy or wall
            x1 = c * move[0]
            y1 = c * move[1]
            there = this.whatsHere(x + x1, y + y1)
            if (there === 'n') {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
            } else if (there[0] === whosOpponent) {
              if (this.checkMove(piece, x, y, x + x1, y + y1, whosOpponent)) {
                moves.push([x + x1, y + y1])
              }
              more = false
            } else more = false
            c++
          }
        }
        break
      default:
        return []
    }
    return moves
  }

  renderSquare (x, y) {
    if (x === 9) {
      var pieceStr = 'b'
      var pieceNum = y
      if (y >= 10) {
        pieceNum = y - 10
        pieceStr = 'w'
      }
      var classes = 'tile '
      if (pieceStr === 'w') classes += 'whiteTile'
      else classes += 'blackTile'
      switch (pieceNum) {
        case 0:
          pieceStr += 'r'
          break
        case 1:
          pieceStr += 'h'
          break
        case 2:
          pieceStr += 'b'
          break
        case 3:
          pieceStr += 'q'
          break
        default:
          pieceStr += 'q'
          break
      }
      classes += ' chessButtons'

      return (
        <Square
          src={pieceStr}
          key={pieceStr}
          onMouseEnter={console.log('I dont work')} //this.handleHover(x, y)}
          onClick={() => this.handleClick(x, y)}
          className={classes}
        />
      )
    }
    //console.log("rendersquare  called: x=",x, " y=",y, " square=", this.state.squares[x][y]);
    var char = 'abcdefghijklmnopqrstuvwxyz'.charAt(x)
    var str = char + (y + 1)

    classes = 'tile '
    var isWhite = (x % 2 === 0) & (y % 2 === 0) || (x % 2 === 1) & (y % 2 === 1)
    if (isWhite) {
      classes += 'whiteTile'
    } else {
      classes += 'blackTile'
    }

    const selected = this.state.selected
    if ((selected[0] === x) & (selected[1] === y)) {
      classes += ' selectedTile'
    }
    const moves = this.state.selectedMoves
    //update movable class
    if (moves) {
        if(moves.length > 0) {
        moves.forEach(function (move) {
          if ((move[0] === x) & (move[1] === y)) {
            classes += ' movableTile'
          }
        })
      }
    }
    return (
      <Square
        src={this.state.squares[x][y]}
        key={str}
        onMouseOver={() => this.handleHover(x, y)}
        onClick={() => this.handleClick(x, y)}
        className={classes}
      />
    )
  }
  testSquares = () => {
    const squares = this.state.squares.slice()

    const boardStart = {
      board: [
        ['br', 'n', 'n', 'n', 'bk', 'n', 'n', 'br'],
        ['bp', 'bp', 'bp', 'bp', 'bp', 'n', 'n', 'bp'],
        ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
        ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
        ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
        ['n', 'n', 'n', 'n', 'n', 'n', 'n', 'n'],
        ['wp', 'n', 'wb', 'wp', 'wp', 'wp', 'wp', 'wp'],
        ['wr', 'n', 'n', 'wk', 'n', 'n', 'n', 'wr']
      ]
    }
    for (var i = 0; i < 8; i++) {
      for (var j = 0; j < 8; j++) {
        var str = boardStart.board[i][j]
        squares[j][i] = str
      }
    }
    var selected = [null, null]
    var selectedMoves = []
    var whitesTurn = true
    var rooksMoved = [false, false, false, false]
    var kingsMoved = [false, false]
    this.setState({
      squares,
      selected,
      selectedMoves,
      whitesTurn,
      rooksMoved,
      kingsMoved
    })
  }

  render () {
    var board = []

    var y, x
    if (this.state.pawnSelection === 'w') {
      const pawnSel = []
      for (y = 0; y < 4; y++) {
        pawnSel.push(this.renderSquare(9, y + 10))
      }
      board.push(
        <div className='board-row'>
          <button className='tile whiteTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          <button className='tile blackTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          {pawnSel}
          <button className='tile blackTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          <button className='tile whiteTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
        </div>
      )
    }
    for (y = 0; y < 8; y++) {
      // board.push(<div className='board-row'>);
      const row = []
      row.push()
      for (x = 0; x < 8; x++) {
        row.push(this.renderSquare(x, y))
      }
      board.push(
        <div className='board-row' key={'row' + y}>
          {row}
        </div>
      )
    }
    if (this.state.pawnSelection === 'b') {
      const pawnSel = []
      for (y = 0; y < 4; y++) {
        pawnSel.push(this.renderSquare(9, y))
      }
      board.push(
        <div className='board-row'>
          <button className='tile blackTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          <button className='tile whiteTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          {pawnSel}
          <button className='tile whiteTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
          <button className='tile blackTile'>
            <img src='n.png' alt='bk' className='chessImg' />
          </button>
        </div>
      )
    }
    var btnclass = this.state.whitesTurn ? 'whiteB' : 'blackB'
    var btnText = this.state.whitesTurn ? "White's Turn" : "Black's Turn"
    board = <div className='chessBoard'>{board}</div>
    return (
      <div className='chessContainer'>
        <div className='chessApp'>
          <div className='chessButtons'>
            <button className={btnclass}>{btnText}</button>
          </div>
          {board}
          <div className='chessButtons'>
            <button className={btnclass}>{this.state.check}&nbsp;</button>
            {/* Add check threat button?*/}
          </div>
        </div>
        <div className='chessButtons'>
          <button className='blackB chessButtonBig' onClick={this.goBack}>
            Go back 1
          </button>
          <button className='blackB chessButtonBig' onClick={this.defaultBoard}>
            New game
          </button>
        </div>
      </div>
    )
  }
}
export default Board
