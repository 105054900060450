import React, {useState} from 'react'
import TicTac from './TicTac.js'
import RulesPanel from './RulesPanel.js'
import Constants from "./uttt_constants.js"
const {NAUGHT, CROSS, EMPTY, EMPTY_BOARD} = Constants
//start / empty ult board is array with unique empty boards.
const EMPTY_ULT_BOARD = [[...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD]]
const EMPTY_ULT_DISPLAY = [...EMPTY_BOARD]
//Winning indexes (indexed by 1 based arrays)
const possibleWinsOneIndexed = [
  [2, 5, 8],
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [1, 5, 9],
  [3, 5, 7],
  [1, 4, 7],
  [3, 6, 9]
]
const possibleWins = possibleWinsOneIndexed.map(winArray => {
  return winArray.map(oneIndexed => oneIndexed - 1)
})


/* Ultimate Tic Tac Toe */
function UltTicTac() {
  const [ultBoard, setUltBoard] = useState(EMPTY_ULT_BOARD)
  const [naughtsTurn, setNaughtsTurn] = useState(false)
  const toggleTurn = () => {
    setNaughtsTurn(naughtsTurn => !naughtsTurn)
  }
  const currentPlayer = naughtsTurn ? NAUGHT : CROSS
  const [currBoard, setCurrBoard] = useState(-1)
  const [ultBoardWins, setUltBoardWins] = useState(EMPTY_ULT_DISPLAY)
  const [darkMode, setDarkMode] = useState(false)
  const toggleDarkMode = () => {
    setDarkMode(darkMode => !darkMode)
  }


  function checkWinMajor(megaIndex) {
    possibleWins.forEach(win =>{
      if (win.includes(megaIndex)) {
        debugger
        const [indexA, indexB, indexC] = win
        const playerA = ultBoardWins[indexA]
        const playerB = ultBoardWins[indexB]
        const playerC = ultBoardWins[indexC]

        const isWinner =  (playerA === playerB) &&
                          (playerB === playerC) &&
                          (playerA !== EMPTY)
        
        if (isWinner) {
          console.log("Winner Winner!")
        }
      }
    })
  }
  // Scans a regular board for a winner.
  //updates ultBoardWins with the winner provided there isn't already one.
  //uses brute force algorithm to check win (not efficient)
  function checkWin(megaIndex) {
    if (ultBoardWins[megaIndex] === EMPTY) {
      //This board has yet to declare a winner let's check it.
      

      possibleWins.forEach(win => {
        const [indexA, indexB, indexC] = win
        const playerA = ultBoard[megaIndex][indexA]
        const playerB = ultBoard[megaIndex][indexB]
        const playerC = ultBoard[megaIndex][indexC]

        const isWinner =  (playerA === playerB) &&
                          (playerB === playerC) &&
                          (playerA !== EMPTY)
        if (isWinner) {
          const newUltBoardWins = [...ultBoardWins]
          newUltBoardWins[megaIndex] = playerA
          setUltBoardWins(newUltBoardWins)
          
          return playerA
        } 
      })
    }
    return EMPTY
  }

  function makeMove (index, megaIndex) {
    //is this a valid move?
    //Is the selected square empty?
    const isEmpty = ultBoard[megaIndex][index] === EMPTY
    //Are we in the correct Board?
    const correctBoard = currBoard === -1 | currBoard === megaIndex

    if (isEmpty & correctBoard){
      const newUltBoard = [...ultBoard]
      newUltBoard[megaIndex][index] = currentPlayer
      setUltBoard(newUltBoard)
      checkWin(megaIndex)
      toggleTurn()
      setCurrBoard(index)
    } else {
      console.log("Invalid Move:")
      if(!isEmpty) console.log("Square is not empty")
      if(!correctBoard) console.log("Incorrect Mini Board")
    }
  }

  const renderSmallBoard = (i) => {
    return (
    <TicTac 
      board={ultBoard[i]}
      megaIndex={i}
      makeMove={makeMove} 
      toggleTurn={toggleTurn}
      isActive={i === currBoard | currBoard === -1}
      currentPlayer={currentPlayer}
      currentWinner={ultBoardWins[i]}
    />)
  }
  let darkStr = ""
  if (darkMode) {
    darkStr = " dark-mode"
  }
  return (
    <div className={'game-container' + darkStr}>
      <RulesPanel 
        darkMode={darkMode} 
        toggleDarkMode={toggleDarkMode}
      />
      <div className='ult-board'>
        <div className="ult-board-row">
          {renderSmallBoard(0)}
          {renderSmallBoard(1)}
          {renderSmallBoard(2)}
        </div>
        <div className="ult-board-row low-row">
          {renderSmallBoard(3)}
          {renderSmallBoard(4)}
          {renderSmallBoard(5)}
        </div>
        <div className="ult-board-row low-row">
          {renderSmallBoard(6)}
          {renderSmallBoard(7)}
          {renderSmallBoard(8)}
        </div>
      </div>
      
    </div>
  )
}

export default UltTicTac

//Old extra code
// class Board extends Component {
  //   render () {
    //     return (
      //       <div className='tttGame'>
      //         <Game></Game>
      //       </div>
      //     )
      //   }
      // }
      
      // export class tictac extends Component {
        //   render () {
          //     return (
            //       <div className='tttGamePage'>
            //         <div className='tictac'>
            //           <Game size='3' />
            //         </div>
            //       </div>
            //     )
            //   }
            // }
            
            // MegaBoard: [],
            // MBWins: []
            
            
            
            // async componentDidMount () {
            //   this.defaultBoard()
            // }
            
            // defaultBoard = () => {
            //   var size = this.props.size
            //   var MegaBoard = []
            
            //   for (var i = 0; i < size * size; i++) {
            //     // var miniBoard = Array(size * size).fill(EMP)
            //     // MegaBoard.push(miniBoard)
            //     MegaBoard.push(EMP)
            //   }
            //   var MBWins = Array(size).fill(EMP)
            //   this.setState({
            //     MegaBoard,
            //     MBWins,
            //     size
            //   })
            // }
            
            // function onBoardClick (index) {
            //   var MB = this.state.MegaBoard
            //   MB[index] = CRS
            //   console.log('index called from: ' + index)
            //   this.setState({ MegaBoard: MB })
            // }
            
            // function renderBoard () {
            //   var board = []
            //   for (var i = 0; i < size; i++) {
            //     var row = []
            //     for (var j = 0; j < size; j++) {
            //       var index = i * 3 + j
            //       row.push(this.renderTile(index))
            //     }
            //     board.push(row)
            //   }
            //   return <div className='tttBoard'>{board}</div>
            // }
            // function renderTile (index) {
            //   var state = this.state.MegaBoard[index]
            //   var text = index + 'E'
            //   if (this.state.MegaBoard[index] === naught) text = index + 'O' // Knaught or Cross
            //   if (this.state.MegaBoard[index] === CRS) text = index + 'X' // Cross or Knaught?
            //   var strClass = 'Tile'
            //   if (state === naught) {
            //     strClass += ' red'
            //   } else if (state === CRS) {
            //     strClass += ' blue'
            //   } else strClass += ' white'
            
            //   return (
            //     <Tile
            //       className={strClass}
            //       key={text}
            //       onClick={() => this.onBoardClick(index)}
            //     />
            //   )
            // }
            // var MegaBoard = Array(size * size).fill(null)
            // for (var i = 0; i < size * size; i++) {
            //   MegaBoard[i] =
            // }
            