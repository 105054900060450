import React from 'react'
//import logo from './logo.svg';
//bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
//Router and Navbar
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
//App imports
// import fowchess from './fowchess.js';
import UltTicTac from './components/uttt/UltTicTac.js'
import ImageBox from './components/ImageBox.js'
import chess from './chess/chess.js'
// import signup from './components/signup.js'
import Resume from './resume/Resume.js'
function App () {
  const profBoxProps = {
    size: 4,
    src: 'headshot.png',
    alt: 'headshot',
    title: 'About Me',
    text: 'Welcome to my website! I am a Software Engineer, and an Instructor, with a passion for education.'
  }
  const lineArtProps = {
    size: 3,
    src: 'NatureLineArt.jpg',
    alt: 'LineArt',
    title: 'Contour Line Drawing',
    text: 'Bull Creek Green Belt - Spring 2020'
  }
  const chessProps = {
    size: 3,
    src: 'chessExample.png',
    alt: 'Screenshot of my Chess Application',
    title: 'Chess made with React',
    text: 'After graduating from UTD, a global pandemic just happened to occur. During this time I created this application all from scratch to fill my time, and to demonstrate my abilities to recruiters.'
  }

  const Home = () => (
    <div className='indexHome'>
      <header>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Amaranth'
        ></link>
        <title>Thomas Wolf Home</title>
      </header>
      <div className='appHome'>
        <div className='flex-row'>
          <ImageBox {...profBoxProps} />
        </div>
        <div className='flex-row'>
          <ImageBox {...lineArtProps} />
          <ImageBox {...chessProps} />
        </div>
      </div>
      <footer className='App-footer'>
          <p>This website was programmed in React by Thomas Wolf</p>
      </footer>
    </div>
  )

  return (
    <div className='main'>
      <div className='NavBar'>
        {/* <div className="App-header">
        <p>Thomas Wolf</p>
      </div> */}
        <Navbar variant='dark' className='spaceNav'>
          <Nav className='mr-auto'>
            <Nav.Item>
              <Navbar.Brand href='./'>Home</Navbar.Brand>
              {/* <Nav.Link href="#home">Home</Nav.Link> */}
            </Nav.Item>
            <Nav.Item>
              <NavDropdown title='Games' id='basic-nav-dropdown'>
                <NavDropdown.Item href='Chess'>Chess</NavDropdown.Item>
                <NavDropdown.Item href='UltTicTacToe'>
                  Ultimate Tic Tac Toe
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown.Divider /> */}
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='Resume'>My Resume</Nav.Link>
              {/* <Nav.Link href="#home">Home</Nav.Link> */}
            </Nav.Item>
            {/* <Nav.Link href="FogOfWarChess">FOW Chess</Nav.Link> */}
            {/* <Nav.Link href="Restaurant">Floor Plan Demo</Nav.Link> */}
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link href='https://www.linkedin.com/in/thomas-wolf-264b2512b/'>
                <img
                  src='linkedin.png'
                  alt='Linkedin'
                  className='linkPic mr-sm-2'
                />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href='https://www.hackerrank.com/thomasrussellwo1'>
                <img
                  src='hr.png'
                  alt='hackerrank'
                  className='linkPic mr-sm-2'
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='https://github.com/TR-Wolf'>
                <img src='git.png' alt='Github' className='linkPic mr-sm-2' />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>

      <Router>
        <Switch>
          <Route path='/' exact component={Home} />
          {/* <Route path="/FogOfWarChess" component={fowchess} /> */}
          <Route path='/Chess' exact component={chess} />
          <Route path='/UltTicTacToe' exact component={UltTicTac} />
          {/* <Route path='/Restaurant' component={rest} /> */}
          <Route path='/resume' component={Resume} />
          {/* <Route path="/signup" component={signup} /> */}
        </Switch>
      </Router>
    </div>
  )
}
export default App
