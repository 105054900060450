import React from 'react'

function Tile({index, value, onClick}) {
  return (
      <button onClick={onClick} className={"tt-tile " + value}>
      </button>
    )
  
}

export default Tile
