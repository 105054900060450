//Board Values
const Constants = {
    EMPTY: 0,
    NAUGHT: 1,
    CROSS: 2,
    EMPTY_BOARD: [0, 0, 0, 0, 0, 0, 0, 0, 0]
}
// const UP = 1
// const DOWN = -1
// const RIGHT = 2
// const LEFT = -2
export default Constants