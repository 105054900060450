import React, {useState} from 'react'
import Switch from 'react-switch'

function RulesPanel ({darkMode, toggleDarkMode}) {
    const [opened, setOpened] = useState(false)
    const toggleOpened = () => {
        setOpened(opened => !opened)
    }
    let titleStr = "Rules and Modifiers"
    if(opened) {
        titleStr = "△ " + titleStr + " △"
    } else {
        titleStr = '▽ ' + titleStr + ' ▽'

    }
    return (
        <div className='rules-panel'>
            <div 
                onClick={toggleOpened}
                className='rules-button'
            >
                {titleStr}
            </div>
            {opened ? 
            <div className='rule'>
                <label>
                    Dark Mode
                </label>
                <Switch
                    className="switch"
                    onChange={toggleDarkMode} 
                    checked={darkMode}
                    />
            </div>
            : null}
        </div>
    )
}

export default RulesPanel
