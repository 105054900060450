import React from 'react'
import Tile from './Tile.js'

import Constants from "./uttt_constants.js"
const {NAUGHT, CROSS, EMPTY} = Constants

function TicTac({board, megaIndex, makeMove, isActive, currentPlayer, currentWinner}) {
  
  function renderSquare(i) {
    const empty = board[i] === EMPTY
    let value = "empty"
    if (!empty)
      value = board[i] === NAUGHT ? "naught" : "cross"

    return (
    <Tile 
      index={i} 
      value={value}
      onClick={()=>makeMove(i, megaIndex)}
    />)
  }

  let classes = "tt-board"
  if (isActive) {
    if (currentPlayer === NAUGHT) {
      console.log("new current player is NAUGHTS")
      classes += " active-naught"
    } else if (currentPlayer === CROSS) {
      console.log("and crosses now!")
      classes += " active-cross"
    }
  }

  if (currentWinner === NAUGHT) {
    classes += " won-naught"
  } else if (currentWinner === CROSS) {
    classes += " won-cross"
  }

  //Render JSX
  return (
    <div className={classes}>
      <div className="tt-board-row">
        {renderSquare(0)}
        {renderSquare(1)}
        {renderSquare(2)}
      </div>
      <div className="tt-board-row low-row">
        {renderSquare(3)}
        {renderSquare(4)}
        {renderSquare(5)}
      </div>
      <div className="tt-board-row low-row">
        {renderSquare(6)}
        {renderSquare(7)}
        {renderSquare(8)}
      </div>
    </div>
  )
}

export default TicTac

// class Game extends React.Component {
//   render() {
//     return (
//       <div className="game">
//         <div className="game-board">
//           <Board />
//         </div>
//         <div className="game-info">
//           <div>{/* status */}</div>
//           <ol>{/* TODO */}</ol>
//         </div>
//       </div>
//     );
//   }
// }