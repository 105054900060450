import React from 'react'

function Square({src, onMouseOver, onClick, className}) {
  // For making drag and drop.
  // const onDragOver = ev => {
  //   ev.preventDefault()
  // }
  // this should never happen
  if (src === null) src = 'n'
  var img = (
    <img src={'/' + src + '.png'} alt='bk' className='chessImg' draggable />
  )
  return (
    <button
      // onDragOver={(e) => this.onDragOver(e)}
      onMouseOver={onMouseOver}
      className={className}
      onClick={onClick}
    >
      {img}
    </button>
  )

}
export default Square
